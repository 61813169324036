import 'idempotent-babel-polyfill';
import Cookies from 'js-cookie'

const COOKIE_DOMAIN = '.novalend.pl';

class RefererApiClass{
  static COOKIE_NAME = 'referer_url'
  constructor(){

    console.log(`referer found: ${this.getValidReferer()}`);
    console.log(`active referer found: ${this.getActiveReferer()}`);
    
    if(this.getValidReferer()){
      this.save();
    }

    this.getRefererFromCookie();
    
    
  }

  getActiveReferer(){
    if(this.getValidReferer()){
      return this.getValidReferer();
    }
    if(this.getRefererFromCookie()){
      return this.getRefererFromCookie();
    }
    return null;
  }
  
  save(){
    console.log(`save referer in cookie: ${this.getValidReferer()}`);
    Cookies.set(RefererApiClass.COOKIE_NAME, this.getValidReferer(),{
      expires: 1,
      domain: COOKIE_DOMAIN
    });
  }
  
  getRefererFromCookie(){
    const ref = Cookies.get(RefererApiClass.COOKIE_NAME);
    // console.log(`read referer from cookie: ${ref}`);
    return ref;
  }

  getValidReferer(){
    if(this.refererURL() && !this.refererURL().match(/(elitehts\.com|localhost)/i)){
      return this.refererURL();
    }
    return null;
  }

  refererDomain(){
    var matches = String(document.referrer).match(/(?:http|https):\/\/([^/]+)(?:.+)/i);
  
    if(matches){
      return matches[1]
    }
    return null;
  }
  
  
  refererURL(){
    return String(document.referrer);
  }
  
}

class UTMApiClass{
  static COOKIE_NAME = 'saved_utms'
  utm;
  constructor(){
    this.utm = this.getUtm();
    
    if(this.hasUtmInURL()){
      console.log(`utm found in URL`, this.getUtm());
      this.save();
    }
    
    this.utm = this.read();
    

    
    
  }

  hasUtmInURL(){
    return Object.keys(this.utm).length > 0
  }


  getUtm(){
    var params = {};        
    const parameters = new URLSearchParams(window.location.search)

    if( parameters.get('utm_source') ) {
      params['utm_source'] = parameters.get('utm_source');
    }
    if( parameters.get('utm_medium') ) {
      params['utm_medium'] = parameters.get('utm_medium');
    }
    if( parameters.get('utm_campaign') ) {
      params['utm_campaign'] = parameters.get('utm_campaign');
    }
    if( parameters.get('utm_id') ) {
      params['utm_id'] = parameters.get('utm_id');
    }
    if( parameters.get('utm_term') ) {
      params['utm_term'] = parameters.get('utm_term');
    }
    if( parameters.get('utm_content') ) {
      params['utm_content'] = parameters.get('utm_content');
    }
    return params;
  }

  getUtmString(){
    // var utm = this.getUtm();
    var utmString = '';
    if(this.utm){
      for (const [key, value] of Object.entries(this.utm)) {
        utmString += `${key}: ${value}, `;
      }
    }
    return String(utmString).trimEnd(",");
  }
  
  save(){
    console.log(`save UTM in cookie`, this.getUtm());
    Cookies.set(UTMApiClass.COOKIE_NAME, JSON.stringify(this.getUtm()),{
      expires: 90,
      domain: COOKIE_DOMAIN
    });
  }

  read(){
    try {
      var json = JSON.parse(Cookies.get(UTMApiClass.COOKIE_NAME));
      console.log(`UTM found in COOKIE`, json);
      
      if(Object.keys(json).length > 0){
        return json;
      }
      
    } catch (err) {
      console.log(`UTM not saved`, json);
      return null;
    }
    return null;
  }
  
  
}

const RefererApi = new RefererApiClass();
window['RefererApi'] = RefererApi;

const UTMApi = new UTMApiClass();
window['UTMApi'] = UTMApi;
